<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab>
          <template #title>
            <feather-icon icon="TagIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{  $t('Routes.editSoftware')  }}</span>
          </template>
          <software-edit-tab />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{  $t('AppTI.software.documents')  }}</span>
          </template>
          <software-documents-tab/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import tiService from '@/services/informationTecnology.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import SoftwareEditTab from './SoftwareEdit.vue'
import SoftwareDocumentsTab from './SoftwareDocuments.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    SoftwareEditTab,
    SoftwareDocumentsTab,
  },
  setup() {
    const softwareData = ref(null)
    const { fetchSoftware } = tiService()
    fetchSoftware(router.currentRoute.params.softwareId, data => {
      softwareData.value = data
    })

    return {
      softwareData,
    }
  },

}
</script>
