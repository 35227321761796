<template>
  <div>
    <b-card>
      <b-card-header>
        <h2>
          <feather-icon icon="SaveIcon" />
          {{  $t('Routes.editSoftware')  }}
        </h2>
      </b-card-header>
      <b-card-body>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.software.name')" rules="required">
                  <b-form-group :label="$t('AppTI.software.name')">
                    <b-form-input type="text" placeholder="Nombre" v-model="softwareData.nombre" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.filters.provider')" rules="required">
                  <b-form-group :label="$t('AppTI.filters.provider')">
                    <v-select id="provider" v-model="softwareData.proveedorTiId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="texto" :options="providers"
                      :reduce="option => option.id" :disabled="!$can('write', 'MesaAyuda')" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.software.serial')" rules="required">
                  <b-form-group :label="$t('AppTI.software.serial')">
                    <b-form-input type="text" :placeholder="$t('AppTI.software.serial')"
                      v-model="softwareData.serial" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.software.credencials')" rules="required">
                  <b-form-group :label="$t('AppTI.software.credencials')">
                    <b-form-input type="text" :placeholder="$t('AppTI.software.credencials')"
                      v-model="softwareData.credenciales" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.software.description')" rules="required">
                  <b-form-group :label="$t('AppTI.software.description')">
                    <b-form-textarea v-model="softwareData.descripcion" rows="3"
                      placeholder="Software para programacion" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.software.observations')" rules="required">
                  <b-form-group :label="$t('AppTI.software.observations')">
                    <b-form-textarea v-model="softwareData.observaciones" rows="3" placeholder="" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.software.cost')" rules="required">
                  <b-form-group :label="$t('AppTI.software.cost')">
                    <b-form-input type="number" :placeholder="$t('AppTI.software.cost')" v-model="softwareData.costo" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.software.license')" rules="required">
                  <b-form-group :label="$t('AppTI.software.license')">
                    <v-select id="provider" v-model="softwareData.licenciamientoTipoId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="texto" :options="typeLicense"
                      :reduce="option => option.id" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="validationContext" name="FechaCompra" rules="required">
                  <b-form-group :label="$t('AppTI.software.buyDate')" label-for="fecha-compra">
                    <b-form-datepicker id="fecha-compra" v-model="softwareData.fechaCompra" today-button close-button
                      :min="min" locale="es" :state="getValidationState(validationContext)" />
                    <b-form-invalid-feedback>
                      {{  validationContext.errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" v-if="softwareData.licenciamientoTipoId == 2">
                <validation-provider #default="validationContext" name="FechaVencimiento" rules="required">
                  <b-form-group :label="$t('AppTI.software.expirationDate')" label-for="fecha-vencimiento">
                    <b-form-datepicker id="fecha-vencimiento" v-model="softwareData.fechaVencimiento" today-button
                      close-button :min="min" locale="es" :state="getValidationState(validationContext)" />
                    <b-form-invalid-feedback>
                      {{  validationContext.errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" :name="$t('AppTI.software.userQuantity')" rules="required">
                  <b-form-group :label="$t('AppTI.software.userQuantity')">
                    <b-form-input type="number" :placeholder="$t('AppTI.software.userQuantity')"
                      v-model="softwareData.cantidadUsuarios" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{  errors[0]  }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="float-right mt-2 mb-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                <b-spinner v-if="addingSoftware" small class="mr-1" />
                {{  $t('Lists.Edit')  }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BFormGroup,
  BForm,
  BFormInput,
  BFormDatepicker,
  BRow,
  BCol,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required, max, min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import optionService from '@/services/option.service'
import tiService from '@/services/informationTecnology.service'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import router from '@/router'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    BSpinner,

    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      max,
      min,
    }
  },
  setup() {
    const { fetchOptions } = optionService()
    const {
      fetchSoftware,
      updateSoftware,
    } = tiService()

    const providers = ref([])
    const typeLicense = ref([])
    const addingSoftware = ref(false)
    const softwareData = ref({
      proveedorTiId: 0,
      nombre: '',
      conversacionId: 0,
      serial: '',
      descripcion: '',
      observaciones: '',
      credenciales: '',
      costo: '',
      licenciamientoTipoId: 0,
      fechaCompra: '',
      fechaVencimiento: '',
      cantidadUsuarios: 0,
      softwareDocumentos: [],
      archivoSeleccionado: '',
      archivoSeleccionadoNombre: '',
    })

    const blankSoftwareData = {
      proveedorTiId: 0,
      nombre: '',
      conversacionId: 0,
      serial: '',
      descripcion: '',
      observaciones: '',
      credenciales: '',
      costo: '',
      licenciamientoTipoId: 0,
      fechaCompra: '',
      fechaVencimiento: '',
      cantidadUsuarios: 0,
      softwareDocumentos: [],
      archivoSeleccionado: '',
      archivoSeleccionadoNombre: '',
    }

    const filesTableFields = [
      {
        key: 'nombre',
        label: i18n.t('classification.edit.labelArchive'),
        sortable: false,
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
      },
    ]

    const resetSoftwareData = () => {
      softwareData.value = JSON.parse(JSON.stringify(blankSoftwareData))
    }

    const {
      refFormObserver,
      resetForm,
      getValidationState,
    } = formValidation(resetSoftwareData)

    fetchOptions({ nombreDominio: 'proveedoresTi' }, data => {
      providers.value = data
    })

    fetchOptions({ nombreDominio: 'tiposLicenciamiento' }, data => {
      typeLicense.value = data
    })

    fetchSoftware(router.currentRoute.params.softwareId, data => {
      softwareData.value = data
    })

    const onSubmit = () => {
      addingSoftware.value = true
      updateSoftware(softwareData.value)
        .then(() => {
          addingSoftware.value = false
        })
    }
    return {
      providers,
      typeLicense,
      addingSoftware,
      filesTableFields,
      softwareData,
      refFormObserver,
      resetForm,
      getValidationState,
      onSubmit,
    }
  },
}
</script>
