<template>
  <div>
    <b-card>
      <b-card-header>
        <h2>
          <feather-icon icon="LayersIcon" />
          {{ $t('Routes.documentsSoftware') }}
        </h2>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-overlay
              variant="white"
              :show="showLoad"
              spinner-variant="primary"
              blur="0"
              opacity=".75"
              rounded="sm"
            >
              <b-row>
                <b-modal
                  v-model="showModal"
                  hide-footer
                >
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('AppTI.equipment.documentType')"
                      rules="required"
                    >
                      <b-form-group :label="$t('AppTI.equipment.documentType')">
                        <v-select
                          id="marcas"
                          v-model="auxData.documentoTipoId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="texto"
                          :options="types"
                          :reduce="option => option.id"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <h4>{{ $t('AppTI.equipment.documents') }}</h4>
                    <file-uploader
                      v-model="auxData.archivoSeleccionado"
                      :file-name.sync="auxData.archivoSeleccionadoNombre"
                      @on-upload-file="onAddFile"
                    />
                  </b-col>
                </b-modal>

                <b-col cols="12">
                  <b-col
                    offset-md="9"
                    md="3"
                  >
                    <b-dropdown
                      variant="outline-primary"
                      block
                      menu-class="w-100"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="CommandIcon"
                          size="16"
                          class="align-middle"
                        />
                        {{ $t('Components.ListHeader.Actions') }}
                      </template>
                      <b-dropdown-item @click="addDocuments">
                        <feather-icon
                          icon="CalendarIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Routes.addDocumentSofware') }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-table
                    :items="documents"
                    :fields="filesTableFields"
                    responsive
                  >
                    <template #cell(actions)="data">
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item @click="onDeleteFile(data.item)">
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="onDownloadFile(data.item)">
                          <feather-icon icon="DownloadCloudIcon" />
                          <span class="align-middle ml-50">{{ $t('AppPrevious.AddEdit.downloadFile') }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </b-col>

              </b-row>
            </b-overlay></b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BTable,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FileUploader from '@/components/FileUploader.vue'
import vSelect from 'vue-select'
import tiService from '@/services/informationTecnology.service'
import optionService from '@/services/option.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import i18n from '@/libs/i18n'
import { saveAs } from 'file-saver'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BTable,
    BOverlay,
    BDropdown,
    BDropdownItem,
    ValidationObserver,
    ValidationProvider,

    vSelect,
    FileUploader,
  },
  setup() {
    const auxData = ref({
      archivoSeleccionado: '',
      archivoSeleccionadoNombre: '',
      documentoTipoId: null,
    })
    const documents = ref([])
    const types = ref([])
    const showLoad = ref(false)
    const showModal = ref(false)
    const {
      fetchDocumentsSoftware,
      fetchDocumentSoftware,
      createDocumentSoftware,
      deleteDocumenSoftware,
    } = tiService()
    const { fetchOptions } = optionService()
    const filesTableFields = [
      {
        key: 'documento.nombreArchivo',
        label: i18n.t('classification.edit.labelArchive'),
        sortable: false,
      },
      {
        key: 'documento.documentoTipo.nombre',
        label: i18n.t('AppTI.equipment.documentType'),
        sortable: false,
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
      },
    ]

    fetchOptions({ nombreDominio: 'documentoTipo' }, data => {
      types.value = data
    })

    fetchDocumentsSoftware(router.currentRoute.params.softwareId, data => {
      documents.value = data
    })

    const onAddFile = () => {
      const document = {
        guid: auxData.value.archivoSeleccionado,
        nombreArchivo: auxData.value.archivoSeleccionadoNombre,
        documentoTipoId: auxData.value.documentoTipoId,
      }
      createDocumentSoftware(router.currentRoute.params.softwareId, document, () => {
        fetchDocumentsSoftware(router.currentRoute.params.softwareId, data => {
          documents.value = data
        })
      })
      auxData.value.archivoSeleccionado = ''
      auxData.value.archivoSeleccionadoNombre = ''

      showModal.value = false
    }
    const onDeleteFile = item => {
      deleteDocumenSoftware(item.softwareId, item.documentoId, () => {
        documents.value = documents.value.filter(f => f.documentoId !== item.documentoId)
      })
    }
    const onDownloadFile = item => {
      showLoad.value = true
      fetchDocumentSoftware(item.softwareId, item.documentoId, data => {
        const file = new File([data], `${item.documento.nombreArchivo}.${item.documento.extension}`)
        saveAs(file, `${item.documento.nombreArchivo}.${item.documento.extension}`)
        showLoad.value = false
      })
    }
    const addDocuments = () => {
      showModal.value = true
    }

    return {
      types,
      onAddFile,
      onDeleteFile,
      onDownloadFile,
      filesTableFields,
      auxData,
      showLoad,
      documents,
      addDocuments,
      showModal,

    }
  },
}
</script>
